import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Input } from 'src/app/classes/DialogInput';
import { OcppVersionEnum } from 'src/app/lib/commands';
import { ChargingPoint } from 'src/app/lib/stations/stations.model';

@Component({
  selector: 'sc-point-selector',
  templateUrl: './point-selector.component.html',
  styleUrls: ['./point-selector.component.scss']
})
export class PointSelectorComponent implements OnInit {
  title: string = '';
  action: string = '';
  connectors: ChargingPoint[] = [];
  OcppVersion: OcppVersionEnum;
  VERSION_201 = OcppVersionEnum.VERSION_201_JSON;
  inputs: Input[];
  manual: { evseID: number; connectorID: number } = {
    evseID: null,
    connectorID: null
  };
  pointsForm: FormGroup;
  @ViewChild('pointsFormRef') pointsFormRef: NgForm;
  constructor(public dialogRef: MatDialogRef<PointSelectorComponent>) {}

  ngOnInit() {
    this.pointsForm = new FormGroup({});
    this.inputs?.forEach((input) => {
      const validators = input.required ? [Validators.required] : [];
      this.pointsForm.addControl(
        input.name,
        new FormControl(input?.preset || '', validators)
      );
    });
  }

  sendEvseAndConnector() {
    if (this.inputs?.length) this.pointsFormRef.onSubmit(undefined);
    else this.dialogRef.close(this.manual);
  }

  handleSubmit() {
    if (this.pointsForm.valid) {
      this.dialogRef.close({ ...this.pointsForm.value, ...this.manual });
    }
  }

  chooseConnector(connector: ChargingPoint) {
    const { EvseNumber, ConnectorID } = connector;
    this.manual.evseID = EvseNumber;
    this.manual.connectorID = ConnectorID;
    if (!this.inputs?.length) {
      this.dialogRef.close({ evseID: EvseNumber, connectorID: ConnectorID });
    }
  }

  fillValue(inputFieldName: string, value: string) {
    this.pointsForm.controls[inputFieldName].setValue(value);
  }

  /**
   * OCPP v1.6 - CONNECTOR_ID
   * OCPP v2.0.1 - CONNECTOR_ID and EVSE_ID
   *
   * When we use Ocpp version in 1.6. We use EVSE_ID as CONNECTOR_ID.
   * */
  getEVSENameByOcppVersion() {
    return this.OcppVersion === this.VERSION_201 ? 'EVSE_ID' : 'CONNECTOR_ID';
  }
}
