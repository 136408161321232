import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateModule } from 'src/app/modules/date.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FileUploadModule } from 'ng2-file-upload';
import { PipesModule } from '../../pipes/pipes.module';
import { AddOwnerComponent } from './add-owner/add-owner.component';
import { AlertComponent } from './alert/alert.component';
import { DateComponent } from './date/date.component';
import { DialogService } from './dialog.service';
import { ImportFileComponent } from './import-file/import-file.component';
import { InputComponent } from './input/input.component';
import { MultipleInputComponent } from './multiple-input/multiple-input.component';
import { PromptComponent } from './prompt/prompt.component';
import { SelectComponent } from './select/select.component';
import { PointSelectorComponent } from './point-selector/point-selector.component';
import { OCPPConfigSelectorComponent } from './ocpp-config-selector/ocpp-config-selector.component';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { UpdateFirmwareComponent } from './update-firmware/update-firmware.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddFirmwareComponent } from './add-firmware/add-firmware.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MonitoringReportComponent } from './monitoring-report/monitoring-report.component';
import { ClearMonitoringComponent } from './clear-monitoring/clear-monitoring.component';
import { SetMonitoringComponent } from './set-monitoring/set-monitoring.component';
import { GetAllDisplayMessagesComponent } from './get-all-display-messages/get-all-display-messages.component';
import { SetDisplayMessageComponent } from './set-display-message/set-display-message.component';
import { GetChargingProfileComponent } from './get-charging-profile/get-charging-profile.component';
import { RoamingTermsAndConditionsComponent } from './roaming-terms-and-conditions/roaming-terms-and-conditions.component';
import { RemoveOwnerComponent } from './remove-onwer/remove-owner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatInputModule,
    MatDialogModule,
    DateModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    FileUploadModule,
    MatTooltipModule,
    DirectivesModule,
    MatSlideToggleModule
  ],
  declarations: [
    AlertComponent,
    DateComponent,
    PromptComponent,
    SelectComponent,
    AddOwnerComponent,
    RemoveOwnerComponent,
    InputComponent,
    MultipleInputComponent,
    ImportFileComponent,
    PointSelectorComponent,
    OCPPConfigSelectorComponent,
    CustomerInformationComponent,
    UpdateFirmwareComponent,
    AddFirmwareComponent,
    MonitoringReportComponent,
    ClearMonitoringComponent,
    SetMonitoringComponent,
    GetAllDisplayMessagesComponent,
    SetDisplayMessageComponent,
    GetChargingProfileComponent,
    RoamingTermsAndConditionsComponent
  ],
  providers: [DialogService]
})
export class DialogModule {}
