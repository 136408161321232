import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { DialogService } from '../dialog/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snackbarConfig } from '../../defaults';
import { GlobalFunctions } from '../global-functions/global-functions.service';
import { CommandRequestsService } from '../command-requests/command-requests.service';
import { UsersService } from '../users/users.service';
import { interval, Subscription } from 'rxjs';
import { UnitType } from 'src/app/types/UnitType';
import { UserService } from 'src/app/lib';
import { TranslateService } from '@ngx-translate/core';
import { WhitelabelInstance } from '../whitelabel/whitelabel-instance.service';

@Injectable()
export class PointService {
  selectedPoint: any = null;

  constructor(
    private _http: HttpInterceptorService,
    private dialog: DialogService,
    private snackBar: MatSnackBar,
    private gf: GlobalFunctions,
    private cmdReq: CommandRequestsService,
    private usersService: UsersService,
    private userService: UserService,
    private whiteLabelInstance: WhitelabelInstance,
    private translate: TranslateService
  ) { }

  startAs(point): Promise<any> {
    return new Promise((resolve) => {
      this.usersService.selectCustomer().then((customer) => {
        if (customer) {
          this.start(point, customer.PK_CustomerID).then((res) => {
            resolve(res);
          });
        } else {
          resolve(false);
        }
      });
    });
  }
  startAsOwner(point): Promise<any> {
    if (!point.FK_DefaultCustomerID) return;

    return new Promise((resolve) => {
      this.start(point, point.FK_DefaultCustomerID).then((res) => {
        resolve(res);
      });
    });
  }
  startSelf(point): Promise<boolean> {
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        this.start(point, user.PK_CustomerID).then((res) => {
          resolve(res);
        });
      });
    });
  }
  stop(boxID, sessionID): Promise<any> {
    return new Promise((resolve) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_STOP_CHARGING'),
          null,
          this.translate.instant('STOP_CHARGING')
        )
        .subscribe((accepted) => {
          if (accepted) {
            this._http
              .get('Commands/RemoteStop/' + boxID + '/' + sessionID)
              .subscribe(
                (res) => {
                  //this.snackBar.open('Point stopped', null, snackbarConfig);
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  forceStop(pointID): Promise<boolean> {
    return new Promise((resolve) => {
      //Be careful with this. Will
      this._http.get('ServiceSessions/EndServiceSession/' + pointID).subscribe(
        (res) => {
          if (res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  getSessionByPoint(pointID): Promise<boolean> {
    return new Promise((resolve) => {
      this._http
        .get('ServiceSessions/ActiveChargingSessionByPoint/' + pointID)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  getOCPPLogs(boxID, dateFrom, dateTo): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get(
          'ChargingBoxes/LogOcppByDate/' +
          boxID +
          '/' +
          dateFrom +
          '/' +
          dateTo +
          '/true'
        )
        .subscribe(
          (res) => {
            //res.logs = res.logs.filter(item=>item.messageAction==='MeterValues').map(item=>item.message.meterValue[0]);
            resolve(res);
          },
          (err) => {
            resolve(false);
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
          }
        );
    });
  }
  getMeterValueLogs(pointID, dateFrom, dateTo): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          'Metervalues/ByPointAndDate/' +
          pointID +
          '/' +
          dateFrom +
          '/' +
          dateTo +
          '/true'
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getConnectionLogs(boxID, dateFrom, dateTo): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get(
          'ChargingBoxes/GetConnectionsActivityByDate/' +
          boxID +
          '/' +
          dateFrom +
          '/' +
          dateTo
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            resolve(false);
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
          }
        );
    });
  }

  setAvailable(boxID, evseID, connectorID) {
    return this._http.post(`Commands/ChangeAvailability`, {
      IsOperative: true,
      ChargingBoxID: boxID,
      evseId: evseID,
      connectorID_OCPP20: connectorID
    });
  }

  forcePutBackInQ(sessionID): Promise<any> {
    return new Promise((resolve) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_USER_RETURN_TO_QUEUE'),
          this.translate.instant('USER_RETURN_TO_QUEUE_DESC')
        )
        .subscribe((doPut) => {
          if (doPut) {
            this._http
              .get('LoadBalancing/ForcePutBackInTheQueue/' + sessionID)
              .subscribe((res) => {
                if (res.success) {
                  resolve(true);
                  this.snackBar.open(
                    this.translate.instant('RETURN_TO_QUEUE_SUCCESS'),
                    null,
                    snackbarConfig
                  );
                } else {
                  resolve(false);
                }
              });
          } else {
            resolve(false);
          }
        });
    });
  }
  changeNamesToSMS(points: any[], index = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!points || !points.length) return;
      points.forEach((point, index) => {
        point.Name = point.ChargerCode;
        this.saveGeneric(point).then(
          (success) => {
            if (index + 1 === points.length) {
              resolve(true);
            }
          },
          (err) => {
            reject(points[index]);
          }
        );
      });
    });
  }
  setSMSCodesASC(points, stationID) {
    const translated = this.translate.instant('PREFIX');
    this.dialog
      .input(translated, translated, this.translate.instant('CHANGE_CODES'))
      .subscribe((prefix) => {
        if (!prefix) return;
        let pointIDs = [];
        points = points.sort((a, b) => {
          return this.gf.sort(a.Name, b.Name);
        });
        points.forEach((point) => {
          pointIDs.push(point.PK_ChargePointID);
        });
        let smsChange = {
          Points: pointIDs,
          SMSPrefix: prefix.toUpperCase(),
          FK_ChargingStationID: parseInt(stationID)
        };
        this._http.post('SMS/ChangeSMSCodes', smsChange).subscribe(
          (res) => {
            this.snackBar.open(
              this.translate.instant('SMS_CODES_CHANGED'),
              null,
              snackbarConfig
            );
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
          }
        );
      });
  }

  changeStation(boxID, wallSettingID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .put(
          'ChargingBoxes/ChangeStationOfABox/' + boxID + '/' + wallSettingID,
          {}
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  private start(point, customerID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_START_CHARGING'),
          null,
          this.translate.instant('START_CHARGING')
        )
        .subscribe((accepted) => {
          if (accepted) {
            point.State = 'Starting';

            this._http
              .post('Commands/RemoteStart', {
                FK_ChargingPointID: point.PK_ChargePointID,
                FK_CustomerID: customerID,
                Origin: 'Dashboard'
              })
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                      errorName: err.errorName
                    }),
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }

  addSlave(point): Promise<any> {
    return new Promise((resolve) => {
      //ChargePoints/AddChargePointToExistingBox/{boxId}/{userID}/{ChargingPointName}/{ParkingSpotName}/{hasRFID}/{hasPhysicalKey}
      this.userService.getUser().subscribe((user) => {
        const transaltedPointName = this.translate.instant('POINT_NAME');
        this.dialog
          .input(
            transaltedPointName,
            transaltedPointName,
            null,
            `${this.translate.instant('MASTER_NAME')}: ${point.Name}`
          )
          .subscribe((pointName) => {
            if (pointName) {
              this._http
                .get(
                  `ChargePoints/AddChargePointToExistingBox/${point.PK_ChargeBoxID}/${pointName}`
                )
                .subscribe({
                  next: (res) => {
                    this.snackBar.open(
                      this.translate.instant('SLAVE_ADDED'),
                      null,
                      snackbarConfig
                    );
                    resolve(true);
                  },
                  error: (err) => {
                    this.snackBar.open(
                      this.translate.instant(
                        'SOMETHING_WENT_WRONG_WITH_ERROR',
                        { errorName: err.errorName }
                      ),
                      this.translate.instant('CLOSE')
                    );
                    resolve(false);
                  }
                });
            } else {
              resolve(false);
            }
          });
      });
    });
  }
  getDiagnostics(boxID, deviceID, startTime?, stopTime?, location?) {
    let request = {
      PK_ChargingBoxID: boxID,
      location: location,
      startTime: startTime,
      stopTime: stopTime,
      deviceID: deviceID
    };
    this._http.post('Commands/GetDiagnostics', request).subscribe((res) => {
      this.startWait(
        res,
        this.translate.instant('DIAGNOSTICS_RETRIEVED'),
        this.translate.instant('SOMETHING_WENT_WRONG'),
        this.translate.instant('GET_DIAGNOSTICS'),
        () => {
          this.waitForDiagnostics(res);
        }
      );
    });
  }
  private waitForDiagnostics(res) {
    this._http
      .get(
        'Commands/GetDiagnosticsFiles/' +
        res.datas.Boxes[0].PK_CommandRequestBoxID
      )
      .subscribe(
        (res) => { },
        (err) => {
          if (err.errorName === 'diagnosticsNotUploadedYet') {
            setTimeout(() => {
              this.waitForDiagnostics(res);
            }, 200);
          } else {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`
            );
          }
        }
      );
  }
  getPoint(id, reload?): Promise<any> {
    return new Promise((resolve) => {
      if (this.selectedPoint && !reload) {
        resolve(this.selectedPoint);
      } else {
        this._http.get('ChargePoints/' + id + '/true').subscribe(
          (point) => {
            if (point) this.selectedPoint = point;
            resolve(point);
          },
          (err) => {
            resolve(null);
          }
        );
      }
    });
  }
  selectPoint(point) {
    this.selectedPoint = point;
  }
  modifyState(point) {
    if (point.LastHeartbeat) {
      if (point.State !== 'Offline') {
        //point.CurrentStatus ==='UpdatingFirmware' && point.State !=='Offline'
        point.StateStyle = point.CurrentStatusPoint;
        point.FilterState = point.CurrentStatusPoint;
        point.ViewState =
          point?.CurrentStatusPoint &&
          this.translate.instant(
            String(point.CurrentStatusPoint).toUpperCase()
          );
        switch (point.CurrentStatusPoint) {
          case 'Available':
            point.FilterState = point.State; //point.CurrentStatusPoint;
            // point.ViewState = this.translate.instant(point.State); //point.CurrentStatusPoint;

            if (point.ChargingSessionID) {
              //Charging in our system but not in box
              point.Warning = this.translate.instant(
                'WARNING_BOX_AVAILABLE_TRANSACTION_ONGING'
              );
            }
            break;
          case 'Preparing':
            //Check charging
            //Charging in our system but not in box = show warning
            if (point.ChargingSessionID) {
              point.Warning = this.translate.instant(
                'WARNING_BOX_PREPARING_TRANSACTION_ONGING'
              );
            }
            // point.ViewState = this.translate.instant('PREPARING');
            point.StatusTip = this.translate.instant(
              'BOX_PREPARING_DESCRIPTION'
            );
            break;
          case 'Charging':
            //Discharging, Occupied, Charging, charging with warning
            if (!point.ChargingSessionID) {
              //Warning
              point.Warning = this.translate.instant(
                'WARNING_BOX_CHARGING_BUT_NO_TRANSACTION'
              );
            }
            //We use system status in this case
            point.FilterState = point.State;
            // point.ViewState = point.State;
            break;
          case 'ChargingWithoutSessionSecondChance':
          case 'ChargingWithoutSession':
            point.StateStyle = 'Charging';
            point.FilterState = 'Charging';
            point.ViewState = this.translate.instant('IS_CHARGING');
            point.Warning = this.translate.instant(
              'WARNING_BOX_CHARGING_BUT_NO_TRANSACTION'
            );
            break;
          case 'ChargingOnly':
            point.Warning = this.translate.instant(
              'WARNING_BOX_CHARGING_BUT_NO_TRANSACTION'
            );
            //We use system status in this case
            point.FilterState = point.State;
            // point.ViewState = point.State;
            break;
          case 'ChargingOnlySecond':
            point.Warning = this.translate.instant(
              'WARNING_BOX_SEND_METER_VALUES_NO_TRANSACTION'
            );
            //We use system status in this case
            point.FilterState = point.State;
            // point.ViewState = point.State;
            break;
          case 'SuspendedEV':
            if (point.State === 'Suspended') {
              // point.ViewState = 'Paused';
              point.FilterState = 'Suspended';
            } else {
              // point.ViewState = 'Paused by car';
              point.StatusTip = this.translate.instant('PAUSED_BY_CAR_HINT');
            }
            break;
          case 'SuspendedEVSE':
            if (point.State === 'Suspended') {
              // point.ViewState = 'Paused';
              point.FilterState = 'Suspended';
            } else {
              // point.ViewState = 'Paused by box';
            }

            break;
          case 'Finishing':
            //Check charging
            if (point.ChargingSessionID) {
              point.Warning = this.translate.instant(
                'WARNING_BOX_FINISHING_TRANSACTION_ONGING'
              );
            }
            // point.ViewState = 'Finishing';
            point.StatusTip = this.translate.instant('FINISHING_DESCRIPTION');
            break;
          case 'Reserved':
            // point.ViewState = 'Reserved';
            break;
          case 'Unavailable':
            if (point.CurrentStatus == 'UpdatingFirmware') {
              // point.ViewState = 'Updating';
              point.FilterState = 'Updating';
              point.StateStyle = point.CurrentStatus;
            } else {
              // point.ViewState = 'Online';
              point.FilterState = 'Online';
            }
            point.Unavailable = true;
            break;
          case 'Faulted':
            // point.ViewState = 'Faulted';
            //Check if charging/transaction ongoing
            break;
          case 'UpdatingFirmware':
            // point.ViewState = 'Updating';
            break;
          default:
            //In case we don't have the status yet, or it is something unknown
            // point.ViewState = point.State;
            point.StateStyle = point.State;
            point.FilterState = point.State;
            break;
        }
      } else {
        point.ViewState = this.translate.instant('OFFLINE');
        point.StateStyle = 'Offline';
        point.FilterState = 'Offline';
        //Check if charging/transaction ongoing
      }
    } else {
      //Never been online
      point.ViewState = this.translate.instant('UNINSTALLED');
      point.StateStyle = 'Uninstalled';
      point.FilterState = 'Uninstalled';
    }
    if (point.PointInstallationStatus === 'Installed') {
      //Show icon beeing tested by our system
    }
  }
  restart(point): Promise<any> {
    return new Promise((resolve?) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_RESTART'),
          null,
          this.translate.instant('RESTART')
        )
        .subscribe((doRestart) => {
          if (doRestart) {
            this._http
              .get('Commands/Reset/' + point.PK_ChargeBoxID + '/1')
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                      errorName: err.errorName
                    }),
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  unlockPoint(point): Promise<any> {
    return new Promise((resolve?) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_UNLOCK_CHARGING_CABLE'),
          null,
          this.translate.instant('UNLOCK')
        )
        .subscribe((doUnlock) => {
          if (doUnlock) {
            this._http
              .get(
                'Commands/UnlockConnector/' +
                point.PK_ChargeBoxID +
                '/' +
                point.EvseNumber
              )
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  clearCache(point): Promise<any> {
    return new Promise((resolve?) => {
      if (!point || !point.PK_ChargeBoxID) return;

      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_CLEAR_CACHE'),
          null,
          this.translate.instant('CLEAR_CACHE')
        )
        .subscribe((doClear) => {
          if (doClear) {
            this._http
              .get('Commands/ClearCache/' + point.PK_ChargeBoxID)
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  clearChargingProfile(point): Promise<any> {
    return new Promise((resolve?) => {
      if (!point || !point.PK_ChargeBoxID) return;

      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_CLEAR_CHARGING_PROFILE'),
          null,
          this.translate.instant('CLEAR_PROFILE')
        )
        .subscribe((doClear) => {
          if (doClear) {
            this._http
              .get('Commands/ClearChargingProfiles/' + point.PK_ChargeBoxID)
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  update(point): Promise<any> {
    return new Promise((resolve?) => {
      if (!point || !point.PK_ChargeBoxID) return;
      let prompt = this.translate.instant('CONFIRM_UPDATE_LATEST_VERSION');
      let content = this.translate.instant('FROM_COMMA_TO', {
        from: point.FirmwareVersion,
        to: point.LatestFirmwareVersion
      });

      this.dialog
        .prompt(prompt, content, this.translate.instant('UPDATE'))
        .subscribe((doUpdate) => {
          if (doUpdate) {
            this._http
              .get('Commands/UpdateToLatestFirmware/' + point.PK_ChargeBoxID)
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  setConfig(point, config): Promise<any> {
    return new Promise((resolve?) => {
      if (!point || !point.DeviceID || !config || !config.key) {
        resolve({
          error: this.translate.instant('MISSING_DEVICEID_OR_CONFIG_KEY'),
          config: config,
          device: point.DeviceID
        });
      } else {
        let newConfig = {
          DeviceID: point.DeviceID,
          PK_ChargingBoxID: point.PK_ChargeBoxID,
          Key: config.key,
          Value: config.value
        };
        this._http.post('Commands/SetConfig', newConfig).subscribe(
          (res) => {
            resolve(res.datas);
          },
          (err) => {
            resolve({
              error: err.errorName,
              config: config,
              device: point.DeviceID
            });
          }
        );
      }
    });
  }
  setAuthentication(boxID, auth: boolean): Promise<any> {
    return new Promise((resolve?) => {
      this._http
        .get('Commands/SetDefaultAuthentication/' + boxID + '/' + auth)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
    });
  }
  checkSupport(action: OCPPFeature, ocppv: OCPPVersions, point) {
    if (!point.ExtraInformation) return true; //Will allow the command to be sent if no info is known
    return point.ExtraInformation[ocppv + '_' + action];
  }
  permanentlyLock(boxID, locked: boolean): Promise<any> {
    return new Promise((resolve?) => {
      this._http
        .get('Commands/SetDefaultPermanentCableLocking/' + boxID + '/' + locked)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            if (locked) {
              this.snackBar.open(
                `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                  errorName: err.errorName
                })}`,
                this.translate.instant('CLOSE')
              );
            } else {
              this.snackBar.open(
                `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                  errorName: err.errorName
                })}`,
                this.translate.instant('CLOSE')
              );
            }
            resolve(false);
          }
        );
    });
  }
  checkIfCharging(point): Promise<any> {
    return new Promise((resolve?) => {
      this._http
        .get('Commands/CheckIfThePointIsCharging/' + point.PK_ChargePointID)
        .subscribe(
          (res) => {
            if (res && res.success) {
              resolve(res);
            } else {
              this.snackBar.open(
                `${this.translate.instant(
                  'UNKNOWN_ERROR'
                )}! ${this.translate.instant('SOMETHING_WENT_WRONG')}`,
                this.translate.instant('CLOSE')
              );
              resolve(false);
            }
          },
          (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
    });
  }
  setDefaultConfig(point): Promise<any> {
    return new Promise((resolve?) => {
      if (!point || !point.PK_ChargeBoxID) return;

      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_SET_DEFAULT_CONFIG'),
          null,
          this.translate.instant('SET_DEFAULT_CONFIG')
        )
        .subscribe((doConfig) => {
          if (doConfig) {
            this._http
              .get('Commands/SetDefaultConfig/' + point.PK_ChargeBoxID)
              .subscribe(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  getConfiguration(point): Promise<any> {
    return new Promise((resolve?, reject?) => {
      this._http
        .get('Commands/GetConfiguration/' + point.PK_ChargeBoxID)
        .subscribe(
          (configuration) => {
            resolve(configuration);
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            reject(err);
          }
        );
    });
  }
  getDefaultConfiguration(boxID): Promise<any> {
    return new Promise((resolve?) => {
      this._http
        .get('Commands/GetExpectedConfiguration/' + boxID)
        .subscribe((configuration) => {
          resolve(configuration.datas);
        });
    });
  }
  toggleMaintenance(point) {
    const confirm = point.IsMaintenance
      ? this.translate.instant('CONFIRM_MAINTENANCE_OFF')
      : this.translate.instant('CONFIRM_MAINTENANCE_ON');
    const buttonAction = point.IsMaintenance
      ? this.translate.instant('TURN_OFF_MAINTENANCE')
      : this.translate.instant('TURN_ON_MAINTENANCE');
    const successMessage = point.IsMaintenance
      ? this.translate.instant('MAINTENANCE_TURNED_OFF')
      : this.translate.instant('MAINTENANCE_TURNED_ON');

    this.dialog.prompt(confirm, null, buttonAction).subscribe((doToggle) => {
      if (doToggle) {
        let state = {
          PK_ChargingBoxID: point.PK_ChargeBoxID,
          newState: !point.IsMaintenance
        };
        this._http.put('ChargingBoxes/PutMaintenanceState', state).subscribe(
          (res) => {
            point.IsMaintenance = !point.IsMaintenance;
            this.snackBar.open(successMessage, null, snackbarConfig);
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                error: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
          }
        );
      }
    });
  }
  toggleActivation(point) {
    this.dialog
      .prompt(
        point.IsActive
          ? this.translate.instant('CONFIRM_DEACTIVATE_POINT')
          : this.translate.instant('CONFIRM_ACTIVATE_POINT'),
        null,
        point.IsActive
          ? this.translate.instant('DEACTIVATE')
          : this.translate.instant('ACTIVATE')
      )
      .subscribe((doToggle) => {
        if (doToggle) {
          let pointToPut = Object.assign({}, point);
          pointToPut.IsActive = !point.IsActive;

          //Must check if the state is something custom, state is also updated in point..
          if (
            ![
              'Online',
              'Occupied',
              'Charging',
              'Suspended',
              'Offline'
            ].includes(point.State)
          )
            pointToPut.State = 'Offline';

          this._http.put('ChargePoints', pointToPut).subscribe(
            (res) => {
              this.snackBar.open(
                point.IsActive
                  ? this.translate.instant('POINT_DEACTIVATED')
                  : this.translate.instant('POINT_ACTIVATED'),
                null,
                snackbarConfig
              );
              point.IsActive = !point.IsActive;
            },
            (err) => {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                  errorName: err.errorName
                }),
                this.translate.instant('CLOSE')
              );
            }
          );
        }
      });
  }
  getConnectivity(boxID, duration: any = 1): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('ChargingBoxes/GetConnectionsActivity/' + boxID + '/' + duration)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  addSeveralOwners(points): Promise<any> {
    return new Promise((resolve) => {
      this.dialog.addOwner(true, true, true).subscribe((ownerObject) => {
        if (ownerObject) {
          let notAdded = '';
          this.snackBar.open(
            `${this.translate.instant('ADDING_MULTIPLE_OWNERS')}...`,
            null,
            snackbarConfig
          );
          let doAddSeveral = (i = 0, points, auth, subscr, semiregister) => {
            if (i >= points.length) {
              //Done
              if (notAdded) {
                this.dialog.alert(
                  this.translate.instant('SOMETHING_WENT_WRONG'),
                  null,
                  notAdded
                );
              }
              this.snackBar.open(
                this.translate.instant('DONE_ADDING_OWNERS'),
                null,
                snackbarConfig
              );
              resolve(true);
            } else {
              let obj = {
                Email: points[i].NewOwnerEmail,
                FK_ChargePointID: points[i].PK_ChargePointID,
                FK_ServicePricingFactorID: 0,
                ValidationKEY: null,
                IsCustomerPayingForSubscription: subscr,
                SemiRegisterIfCustomerNotFound: semiregister,
                appToken: this.whiteLabelInstance.whitelabelID
              };
              this._http
                .post('ChargePoints/ConnectCustomerToPoint', obj)
                .subscribe(
                  (res) => {
                    if (points[i].AuthenticationEnabled !== auth) {
                      //Update authentication

                      this.setAuthentication(
                        points[i].PK_ChargeBoxID,
                        auth
                      ).then((res) => {
                        //Wait until this is done or else we might update the view before it is changed
                        i++;
                        doAddSeveral(i, points, auth, subscr, semiregister);
                      });
                    } else {
                      i++;
                      doAddSeveral(i, points, auth, subscr, semiregister);
                    }
                  },
                  (err) => {
                    //For each error we make an error string to show later
                    console.log(err);
                    notAdded += `${this.translate.instant('ERROR')}: ${err.errorName
                      } (${points[i].DeviceID})\n\n`;
                    i++;
                    doAddSeveral(i, points, auth, subscr, semiregister);
                  }
                );
            }
          };

          doAddSeveral(
            0,
            points,
            ownerObject.authentication,
            ownerObject.IsCustomerPayingForSubscription,
            ownerObject.SemiRegisterIfCustomerNotFound
          );
        } else {
          resolve(false);
        }
      });
    });
  }
  setPointPrice(pointID, pricingFactor): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .post(
          'ServicePricingFactors/ChangeCustomPricingClaimedPoint/' + pointID,
          pricingFactor
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  addOwner(
    pointID,
    boxID,
    originalAuth,
    pricingFactor?: number,
    isSubscriptionPaidByCustomers: boolean = true
  ): Promise<any> {
    return new Promise((resolve) => {
      if (pointID) {
        this.dialog
          .addOwner(originalAuth, isSubscriptionPaidByCustomers)
          .subscribe((ownerObject) => {
            if (ownerObject) {
              let obj = {
                Email: ownerObject.email,
                FK_ChargePointID: pointID,
                FK_ServicePricingFactorID: pricingFactor || 0,
                ValidationKEY: null,
                IsCustomerPayingForSubscription:
                  ownerObject.IsCustomerPayingForSubscription,
                SemiRegisterIfCustomerNotFound:
                  ownerObject.SemiRegisterIfCustomerNotFound,
                appToken: this.whiteLabelInstance.whitelabelID
              };
              this._http
                .post('ChargePoints/ConnectCustomerToPoint', obj)
                .subscribe(
                  (res) => {
                    //FIX: Check response
                    this.snackBar.open(
                      this.translate.instant('OWNER_ADDED'),
                      null,
                      snackbarConfig
                    );

                    if (originalAuth !== ownerObject.authentication) {
                      //Update authentication
                      this.updateAuthentication(
                        ownerObject.authentication,
                        boxID
                      );
                    }
                    resolve(ownerObject.email);
                  },
                  (err) => {
                    if (err.errorName === 'notEnoughPrivileges') {
                      this.snackBar.open(
                        this.translate.instant(
                          'PERMISSION_DENIED_NO_FUCNTION_ACCESS'
                        ),
                        this.translate.instant('CLOSE')
                      );
                    } else {
                      this.snackBar.open(
                        this.translate.instant(
                          'SOMETHING_WENT_WRONG_WITH_ERROR',
                          { errorName: err.errorName }
                        ),
                        this.translate.instant('CLOSE')
                      );
                    }

                    resolve(false);
                  }
                );
            } else {
              resolve(false);
            }
          });
      }
    });
  }
  updateAuthentication(authentication, boxID?, box?) {
    const errorMessage = this.translate.instant('SOMETHING_WENT_WRONG');
    const buttonText = authentication
      ? this.translate.instant('DISABLE_AUTHENTICATION')
      : this.translate.instant('ENABLE_AUTHENTICATION');

    this.setAuthentication(boxID, authentication).then((res) => {
      this.startWait(res, null, errorMessage, buttonText);
    });
  }

  saveGeneric(point): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('ChargePoints', point).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  removeOwner(pointID): Promise<any> {
    return new Promise((resolve) => {
      if (!pointID) return;
      this.dialog
        .removeOwner()
        .subscribe((data) => {
          if (data) {
            this._http
              .get(`ChargePoints/RemoveDefaultCustomer/${pointID}/${data.deactiveCharger}`)
              .subscribe({
                next: (res) => {
                  resolve(true);
                  this.snackBar.open(
                    this.translate.instant('OWNER_REMOVED'),
                    null,
                    snackbarConfig
                  );
                }, error: (err) => {
                  this.snackBar.open(
                    this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                      errorName: err.errorName
                    }),
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              });
          } else {
            resolve(false);
          }
        });
    });
  }
  deletePoint(pointID): Promise<any> {
    return new Promise((resolve) => {
      //ChargePoints/{point_id}/{deleteAssociatedBox}
      this._http.delete('ChargePoints/' + pointID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }

  getPoints(filters?: PointFilters): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('ChargingBoxes/GetBoxesByFilters', filters).subscribe(
        (points) => {
          if (points.datas) {
            resolve(points.datas);
          } else {
            resolve([]);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('ERROR')}: ` + err.errorName,
            this.translate.instant('CLOSE')
          );
          resolve([]);
        }
      );
    });
  }

  getBox(boxID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('ChargingBoxes/GetBoxFull/' + boxID).subscribe(
        (res) => {
          if (res.datas) {
            resolve(res.datas);
          } else {
            reject(this.translate.instant('SOMETHING_WENT_WRONG'));
          }
        },
        (err) => {
          reject(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })
          );
        }
      );
    });
  }
  updateBox(box): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put('ChargingBoxes', box).subscribe(
        (res) => {
          if (res.success) {
            resolve(box);
          } else {
            reject(this.translate.instant('SOMETHING_WENT_WRONG'));
          }
        },
        (err) => {
          reject(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })
          );
        }
      );
    });
  }
  createPriceObject(
    kWhCost: number,
    timeCost: number,
    startupCost: number,
    occupiedCost: number,
    stationID,
    currency,
    countryID,
    timeUnit: UnitType = 'PerMinute',
    occupiedUnit: UnitType = 'PerMinute'
  ): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Countries/ByID/' + countryID).subscribe(
        (country) => {
          this._http
            .get('Services/ChargingServiceStation/' + stationID)
            .subscribe(
              (service) => {
                var pricing = {
                  FK_ServiceID: service.PK_ServiceID,
                  Name: 'Single point',
                  CostPrice: kWhCost,
                  CostPriceVATPercentage: country.VatPercentageCharging,
                  Cost_Charging_Time: timeCost,
                  FixedStartupCost: startupCost,
                  FixedStartupCostVATPercentage: country.VatPercentageStandard,
                  Currency: currency,
                  Keys: this.gf.guid4(),
                  AmpsMax_DefaultPrice: 0,
                  SpeedMaxAmps: 0,
                  Coefficient_Price_MaxAmps: 0,
                  Type_Cost_Charging_Time: timeUnit,
                  Type_Cost_Occupied: occupiedUnit,
                  Cost_Occupied: occupiedCost,
                  Cost_Occupied_VATPercentage: country.VatPercentageStandard,
                  Type_Cost_Charging: 'PerkWh'
                };
                resolve(pricing);
              },
              (err) => {
                resolve(false);
              }
            );
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  createPrice(
    kWhCost: number,
    timeCost: number,
    startupCost: number,
    occupiedCost: number,
    stationID,
    currency,
    countryID,
    timeUnit: UnitType = 'PerMinute',
    occupiedUnit: UnitType = 'PerMinute'
  ): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Countries/ByID/' + countryID).subscribe(
        (country) => {
          this._http
            .get('Services/ChargingServiceStation/' + stationID)
            .subscribe(
              (service) => {
                var pricing = {
                  FK_ServiceID: service.PK_ServiceID,
                  Name: 'Single point',
                  CostPrice: kWhCost,
                  CostPriceVATPercentage: country.VatPercentageCharging,
                  Cost_Charging_Time: timeCost,
                  FixedStartupCost: startupCost,
                  FixedStartupCostVATPercentage: country.VatPercentageStandard,
                  Currency: currency,
                  Keys: this.gf.guid4(),
                  AmpsMax_DefaultPrice: 0,
                  SpeedMaxAmps: 0,
                  Coefficient_Price_MaxAmps: 0,
                  Type_Cost_Charging_Time: timeUnit,
                  Type_Cost_Occupied: occupiedUnit,
                  Cost_Occupied: occupiedCost,
                  Cost_Occupied_VATPercentage: country.VatPercentageStandard,
                  Type_Cost_Charging: 'PerkWh'
                };

                this._http.post('ServicePricingFactors', pricing).subscribe(
                  (response) => {
                    resolve(response.datas);
                  },
                  (err) => {
                    resolve(false);
                  }
                );
              },
              (err) => {
                resolve(false);
              }
            );
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  startWait(
    res,
    message,
    errorMessage,
    functionName,
    callback?,
    failedCallback?
  ): Subscription {
    //Will send empty response if error in the http-request or if user aborts when prompted
    if (!res || !res.datas || !res.datas.PK_CommandRequestID) return;

    const intervalSeconds = 2; //Check response interval
    const timeout = 10; //How long it will check before timeout

    const checkInterval = interval(intervalSeconds * 1000);
    const subscribe: Subscription = checkInterval.subscribe((i) => {
      //Check response by interval
      this.checkResponse(
        res,
        message,
        errorMessage,
        callback,
        failedCallback
      ).then((done) => {
        if (done) {
          subscribe.unsubscribe();
        } //else pending
      });

      const seconds = i * intervalSeconds;
      if (seconds >= timeout) {
        //Timeout
        this.snackBar.open(
          this.translate.instant('TIMEOUT_FUNCTION_SEE_COMMAND_RESPONSES', {
            functionName: functionName
          }),
          this.translate.instant('CLOSE')
        );
        subscribe.unsubscribe();
      }
    });
    return subscribe;
  }
  checkResponse(
    response,
    message,
    errorMessage,
    callback?,
    failedCallback?
  ): Promise<any> {
    return new Promise((resolve) => {
      this.cmdReq.checkStatus(response.datas.PK_CommandRequestID).then(
        (success) => {
          if (success) {
            if (message) {
              this.snackBar.open(message, null, snackbarConfig);
            }
            if (callback) {
              callback();
            }
            resolve(true);
          } else {
            //else pending
            resolve(false);
          }
        },
        (err) => {
          const error = this.translate.instant('ERROR');
          if (errorMessage) {
            this.snackBar.open(
              `${error}! ${errorMessage}. ${error}: ${err.errorName}`,
              this.translate.instant('CLOSE')
            );
          }
          if (failedCallback) {
            failedCallback();
          }
          resolve(true);
        }
      );
    });
  }
  checkIfDatas(res) {
    if (res && res.datas) {
      return res.datas;
    } else if (res && !res.datas) {
      return res;
    } else {
      return null;
    }
  }
  clear() {
    this.selectedPoint = null;
  }
}

export class PointFilters {
  stations?: number[] = [];
  boxModelsWanted?: number[] = [];
  hasAuthentication?: boolean = null;
  ocppVersions?: string[] = [];
  firmwareVersionNotIncluded?: string[] = [];
  firmwareVersionIncluded?: string[] = [];
  isCharging?: boolean = null;
  hasWrongConfiguration?: boolean = null;
  WallSettingsIds: number[] = [];
}

export type OCPPFeature =
  | 'canChangeAuthentication'
  | 'canPermanentlyLockCable'
  | 'supportsChargingProfile'
  | 'supportsFirmwareManagement'
  | 'supportsTriggerMessages'
  | 'supportsUnlockConnector';
export const OCPPFeatures: OCPPFeature[] = [
  'canChangeAuthentication',
  'canPermanentlyLockCable',
  'supportsChargingProfile',
  'supportsFirmwareManagement',
  'supportsTriggerMessages',
  'supportsUnlockConnector'
];

export type OCPPVersions = 'j16';
