import { Observable } from 'rxjs';
import { PromptComponent } from './prompt/prompt.component';
import { AlertComponent } from './alert/alert.component';
import { DateComponent } from './date/date.component';
import { SelectComponent } from './select/select.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { AddOwnerComponent } from './add-owner/add-owner.component';
import { InputComponent } from './input/input.component';
import { Input } from 'src/app/classes/DialogInput';
import { MultipleInputComponent } from './multiple-input/multiple-input.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { TranslateService } from '@ngx-translate/core';
import { ChargingPoint } from 'src/app/lib/stations/stations.model';
import { PointSelectorComponent } from './point-selector/point-selector.component';
import { OcppVersionEnum } from 'src/app/lib/commands';
import { OCPPConfigSelectorComponent } from './ocpp-config-selector/ocpp-config-selector.component';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { UpdateFirmwareComponent } from './update-firmware/update-firmware.component';
import { AddFirmwareComponent } from './add-firmware/add-firmware.component';
import { MonitoringReportComponent } from './monitoring-report/monitoring-report.component';
import { ClearMonitoringComponent } from './clear-monitoring/clear-monitoring.component';
import { SetMonitoringComponent } from './set-monitoring/set-monitoring.component';
import { GetAllDisplayMessagesComponent } from './get-all-display-messages/get-all-display-messages.component';
import { SetDisplayMessageComponent } from './set-display-message/set-display-message.component';
import { GetChargingProfileComponent } from './get-charging-profile/get-charging-profile.component';
import { RoamingTermsAndConditionsComponent } from './roaming-terms-and-conditions/roaming-terms-and-conditions.component';
import { RemoveOwnerComponent } from './remove-onwer/remove-owner.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  prompt(
    title: string,
    content?: string,
    okButton: string = 'Ok',
    cancelButton: string = this.translate.instant('CANCEL'),
    secondOption?: string,
    maxWidth: string = ''
  ): Observable<any> {
    let dialogRef: MatDialogRef<PromptComponent>;

    dialogRef = this.dialog.open(PromptComponent, { maxWidth: maxWidth });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.okButton = okButton;
    dialogRef.componentInstance.cancelButton = cancelButton;
    dialogRef.componentInstance.secondOption = secondOption;

    return dialogRef.afterClosed();
  }

  promptNamed(params: PromptNamedParams): Observable<any> {
    let dialogRef: MatDialogRef<PromptComponent>;

    dialogRef = this.dialog.open(PromptComponent, {
      maxWidth: params.maxWidth
    });
    dialogRef.componentInstance.title = params.title;
    dialogRef.componentInstance.content = params?.content || '';
    dialogRef.componentInstance.okButton = params.okButton || 'Ok';
    dialogRef.componentInstance.cancelButton = params?.cancelButton || 'Cancel';
    dialogRef.componentInstance.secondOption = params?.secondOption;
    dialogRef.componentInstance.data = params?.data;

    return dialogRef.afterClosed();
  }

  select(
    title: string,
    list: Array<any>,
    viewProperty: string,
    placeholder: string = title,
    okButton: string = 'Ok',
    cancelButton: string = 'Cancel'
  ): Observable<any> {
    let dialogRef: MatDialogRef<SelectComponent>;

    dialogRef = this.dialog.open(SelectComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.list = list;
    dialogRef.componentInstance.viewProperty = viewProperty;
    dialogRef.componentInstance.placeholder = placeholder;
    dialogRef.componentInstance.okButton = okButton;
    dialogRef.componentInstance.cancelButton = cancelButton;

    return dialogRef.afterClosed();
  }
  promptDate(
    single: boolean = false,
    message?,
    okButton: string = 'Ok',
    cancelButton: string = this.translate.instant('CANCEL')
  ): Observable<any> {
    let dialogRef: MatDialogRef<DateComponent>;

    dialogRef = this.dialog.open(DateComponent);
    dialogRef.componentInstance.single = single;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.okButton = okButton;
    dialogRef.componentInstance.cancelButton = cancelButton;

    return dialogRef.afterClosed();
  }

  alert(message: string, type?: string, helpText?: string) {
    let dialogRef: MatDialogRef<AlertComponent>;

    dialogRef = this.dialog.open(AlertComponent);
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.helpText = helpText;
    dialogRef.componentInstance.type = type;
    return dialogRef.afterClosed();
  }

  importFile(fields: string[], title = 'Import file'): Observable<any> {
    let dialogRef: MatDialogRef<ImportFileComponent>;

    dialogRef = this.dialog.open(ImportFileComponent);
    dialogRef.componentInstance.requiredFields = fields;
    dialogRef.componentInstance.title = title;

    return dialogRef.afterClosed();
  }
  input(
    message: string,
    placeholder: string,
    action?: string,
    content?: string,
    hint?: string,
    preset?: string,
    textarea: boolean = false,
    optional: boolean = false
  ) {
    let dialogRef: MatDialogRef<InputComponent>;

    dialogRef = this.dialog.open(InputComponent);
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.placeholder = placeholder;
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.action = action;
    dialogRef.componentInstance.hint = hint;
    dialogRef.componentInstance.preset = preset;
    dialogRef.componentInstance.textarea = textarea;
    dialogRef.componentInstance.optional = optional;
    return dialogRef.afterClosed();
  }

  pointSelectorDialog(
    listOfConnectors: ChargingPoint[],
    ocppVersion: OcppVersionEnum,
    inputs?: Input[],
    action?: string,
    title?: string
  ) {
    let dialogRef: MatDialogRef<PointSelectorComponent>;
    dialogRef = this.dialog.open(PointSelectorComponent, { autoFocus: false });
    dialogRef.componentInstance.connectors = listOfConnectors;
    dialogRef.componentInstance.OcppVersion = ocppVersion;
    dialogRef.componentInstance.inputs = inputs;
    dialogRef.componentInstance.title = title || '';
    dialogRef.componentInstance.action = action || '';

    return dialogRef.afterClosed();
  }

  ocppConfigSelectorDialog(
    props?: {
      hideControllerInput?: boolean;
      hideVariableInput?: boolean;
      hideValueInput?: boolean;
      hideAttributeTypeInput?: boolean;
      hideInstanceInput?: boolean;
      hideESVEIDInput?: boolean;
      showComponentCriteria?: boolean;
      ocppVersion?: OcppVersionEnum;
    },
    title?: string,
    action?: string
  ) {
    let dialogRef: MatDialogRef<OCPPConfigSelectorComponent>;
    dialogRef = this.dialog.open(OCPPConfigSelectorComponent, {
      autoFocus: false,
      disableClose: true
    });
    dialogRef.componentInstance.title = title || '';
    dialogRef.componentInstance.action = action || '';
    dialogRef.componentInstance.hideControllerInput =
      props?.hideControllerInput || false;
    dialogRef.componentInstance.hideVariableInput =
      props?.hideVariableInput || false;
    dialogRef.componentInstance.hideValueInput = props?.hideValueInput || false;
    dialogRef.componentInstance.hideAttributeTypeInput =
      props?.hideAttributeTypeInput || false;
    dialogRef.componentInstance.hideInstanceInput =
      props?.hideInstanceInput || false;
    dialogRef.componentInstance.hideESVEIDInput =
      props?.hideESVEIDInput || false;
    dialogRef.componentInstance.showComponentCriteria =
      props?.showComponentCriteria || false;
    dialogRef.componentInstance.ocppVersion =
      props?.ocppVersion || OcppVersionEnum.VERSION_16_JSON;
    return dialogRef.afterClosed();
  }

  customerInformationDialog() {
    let dialogRef: MatDialogRef<CustomerInformationComponent>;
    dialogRef = this.dialog.open(CustomerInformationComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  getMonitoringReportDialog() {
    let dialogRef: MatDialogRef<MonitoringReportComponent>;
    dialogRef = this.dialog.open(MonitoringReportComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  clearVariableMonitoringDialog() {
    let dialogRef: MatDialogRef<ClearMonitoringComponent>;
    dialogRef = this.dialog.open(ClearMonitoringComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  setVariableMonitoringDialog() {
    let dialogRef: MatDialogRef<SetMonitoringComponent>;
    dialogRef = this.dialog.open(SetMonitoringComponent, { autoFocus: false });
    return dialogRef.afterClosed();
  }

  getAllDisplayMessagesDialog() {
    let dialogRef: MatDialogRef<GetAllDisplayMessagesComponent>;
    dialogRef = this.dialog.open(GetAllDisplayMessagesComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  setDisplayMessageDialog() {
    let dialogRef: MatDialogRef<SetDisplayMessageComponent>;
    dialogRef = this.dialog.open(SetDisplayMessageComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }
  getChargingProfileDialog() {
    let dialogRef: MatDialogRef<GetChargingProfileComponent>;
    dialogRef = this.dialog.open(GetChargingProfileComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  updateFirmwareDialog(boxModelID: number) {
    let dialogRef: MatDialogRef<UpdateFirmwareComponent>;
    dialogRef = this.dialog.open(UpdateFirmwareComponent, { autoFocus: false });
    dialogRef.componentInstance.boxModelID = boxModelID;
    return dialogRef.afterClosed();
  }

  addFirmwareDialog(boxModelID: number, hideIsLatestFirmware?: boolean) {
    let dialogRef: MatDialogRef<AddFirmwareComponent>;
    dialogRef = this.dialog.open(AddFirmwareComponent);
    dialogRef.componentInstance.boxModelID = boxModelID;
    dialogRef.componentInstance.hideIsLatestFirmware =
      hideIsLatestFirmware || false;
    return dialogRef.afterClosed();
  }

  multipleInput(
    title: string,
    inputs: Input[],
    action?: string,
    content?: string
  ) {
    let dialogRef: MatDialogRef<MultipleInputComponent>;

    dialogRef = this.dialog.open(MultipleInputComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.inputs = inputs;
    dialogRef.componentInstance.action = action;
    dialogRef.componentInstance.content = content;
    return dialogRef.afterClosed();
  }
  addOwner(auth, subscription, several?: boolean): Observable<any> {
    let dialogRef: MatDialogRef<AddOwnerComponent>;
    dialogRef = this.dialog.open(AddOwnerComponent);
    dialogRef.componentInstance.auth = auth;
    dialogRef.componentInstance.subscription = subscription;
    dialogRef.componentInstance.several = several;

    return dialogRef.afterClosed();
  }

  removeOwner(): Observable<any> {
    let dialogRef: MatDialogRef<RemoveOwnerComponent>;
    dialogRef = this.dialog.open(RemoveOwnerComponent);
    return dialogRef.afterClosed();
  }

  openComponent(component) {
    this.dialog.open(component);
  }

  openRoamingTermsAndCondition() {
    let dialogRef: MatDialogRef<RoamingTermsAndConditionsComponent>;
    dialogRef = this.dialog.open(RoamingTermsAndConditionsComponent, {
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }
}
interface PromptNamedParams {
  title: string;
  content?: string;
  okButton?: string;
  cancelButton?: string;
  secondOption?: string;
  maxWidth?: string;
  data?: { [key: string]: number | string };
}
