<h2 mat-dialog-title>{{ 'ADD_FIRMWARE' | translate }}</h2>
<form
  [formGroup]="addFirmwareForm"
  (ngSubmit)="handleFormSubmit()"
  #formRef="ngForm">
  <div mat-dialog-content class="dialog-content">
    <div class="input-row">
      <input
        type="file"
        (change)="handleFile($event)"
        class="hidden"
        #fileUpload />
      <button
        mat-button
        (click)="fileUpload.click()"
        type="button"
        class="!p-0">
        <mat-icon>upload_file</mat-icon>
        <span
          [ngClass]="{
            'text-red-400': formRef.submitted && fileToUpload == null
          }">
          {{ 'UPLOAD_FIRMWARE_FILE' | translate }} *
        </span>
      </button>
      <p *ngIf="fileToUpload != null" class="!mt-0">{{ fileToUpload.name }}</p>
      <mat-error
        *ngIf="formRef.submitted && fileToUpload == null"
        class="text-[75%]">
        {{ 'REQUIRED' | translate }}
      </mat-error>
    </div>

    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'Firmware version' | translate }}</mat-label>
        <input matInput type="text" formControlName="FirmwareVersion" />
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>
          {{ 'Minimum required firmware version' | translate }}
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="MinFirmwareVersionRequired" />
        <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field floatLabel="auto" class="w-full">
        <mat-label>{{ 'Release date' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="datePicker"
          (click)="datePicker.open()"
          formControlName="ReleaseDate" />
        <button
          matTextSuffix
          mat-icon-button
          *ngIf="addFirmwareForm.controls.ReleaseDate.value"
          (click)="addFirmwareForm.controls.ReleaseDate.reset()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox
        formControlName="IsLatestFirmware"
        [ngClass]="{
          'invisible h-0 w-0': hideIsLatestFirmware,
          'mb-4': !hideIsLatestFirmware
        }">
        {{ 'LATEST_FIRMWARE' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="MustRebootWhenDone">
        {{ 'MUST_REBOOT_WHEN_DONE' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="UseManufacturersCertificate" class="mt-4">
        {{ 'USE_MANUFACTURERS_CERTIFICATE' | translate }}
      </mat-checkbox>
      <button
        mat-icon-button
        class="toolbar-button margin-right"
        matTooltip="{{ 'MANUFACTURERS_CERTIFICATE_HELP' | translate }}">
        <mat-icon>help_outline</mat-icon>
      </button>
    </div>

    <div class="input-row mt-4">
      <mat-form-field class="w-[400px]">
        <mat-label>{{ 'SIGNING_CERTIFICATE' | translate }}</mat-label>
        <textarea
          matInput
          rows="4"
          formControlName="SigningCertificate"></textarea>
        <button
          mat-icon-button
          matTextSuffix
          class="input-helper"
          type="button"
          matTooltip="{{ 'SIGNING_CERTIFICATE_HELP' | translate }}">
          <mat-icon>help_outline</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="input-row">
      <mat-form-field class="w-[400px]">
        <mat-label>{{ 'SIGNATURE' | translate }}</mat-label>
        <textarea matInput rows="4" formControlName="Signature"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="isAdding">
      <mat-spinner *ngIf="isAdding" class="button-spinner"></mat-spinner>
      <span *ngIf="!isAdding">{{ 'ADD_FIRMWARE' | translate }}</span>
    </button>
    <button mat-raised-button (click)="this.dialogRef.close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
