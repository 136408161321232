import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { baseID } from '../../defaults';
import { GlobalFunctions } from '../global-functions/global-functions.service';
import { RolesService } from '../roles/roles.service';
import { DialogService } from '../dialog/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserType } from 'src/app/user-roles';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/lib';
import { TranslateService } from '@ngx-translate/core';
import { CompanyBillingInfo } from 'src/app/classes/Company';

@Injectable()
export class CompanyService {
  companies: any = [];
  company: any;

  constructor(
    private _http: HttpInterceptorService,
    private userService: UserService,
    private gf: GlobalFunctions,
    private rolesService: RolesService,
    private dialog: DialogService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  clear() {
    this.companies = [];
    this.company = null;
  }

  getAll(): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Companies/GetCompaniesForOperator').subscribe(
        (companies) => {
          this.companies = companies.datas;
          resolve(companies.datas);
        },
        (err) => {
          resolve([]);
        }
      );
    });
  }
  generateInvoices(companyID) {
    return this._http.get(`Invoices/GenerateInvoicesForACompany/${companyID}`);
  }
  getBillingInfo(companyID) {
    return this._http.get(`Companies/Billing/${companyID}`);
  }
  updateBillingInfo(billingInfo: CompanyBillingInfo) {
    return this._http.put(`Companies/Billing`, billingInfo);
  }
  getInvoiceAgreements(companyID, startIndex = 0, limit = 20) {
    return this._http.get(
      `Companies/UsersCompanyPaysFor/${companyID}/${startIndex}/${limit}`
    );
  }
  getAllUnverified(paginationStart: number = 0): Observable<any> {
    return this._http.get(`Companies/NeedToBeVerified/${paginationStart}/10`);
  }
  // @TODO: Clean this up a bit
  get(reload?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser().subscribe((user) => {
        if (
          !reload &&
          this.company &&
          this.company.OwnedByCustomerID === user.PK_CustomerID
        ) {
          resolve(this.company);
        } else {
          this._http
            .get('Companies/GetCompanyOwnedByCustomer/' + user.PK_CustomerID)
            .subscribe(
              (company) => {
                if (
                  !company ||
                  (company.errorName && company.errorName === 'notFoundError')
                ) {
                  reject(this.translate.instant('NO_COMPANY_REGISTERED'));
                } else {
                  this.company = company;
                  if (this.company.OwnedByCustomerID === user.PK_CustomerID) {
                    this.company.OwnedByLoggedInUser = true;
                  }
                  resolve(this.company);
                }
              },
              (err) => {
                if (this.rolesService.hasHighlevelAccess(UserType.Admin)) {
                  resolve({ PK_CompanyID: 0 });
                } else {
                  if (err.errorName === 'notFoundError') {
                    reject(this.translate.instant('NO_COMPANY_REGISTERED'));
                  } else {
                    reject(this.translate.instant('SOMETHING_WENT_WRONG'));
                  }
                }
              }
            );
        }
      });
    });
  }
  getPaymentMethods(companyID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('PaymentMethods/ForCompany/' + companyID + '/false')
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getSettlements(companyID, index, limit): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          'CompanySettlements/ForACompany/' +
            companyID +
            '/' +
            index +
            '/' +
            limit
        )
        .subscribe(
          (res) => {
            if (res) {
              resolve(res);
            } else {
              resolve([]); //Empty for no methods
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getSettlement(settlementID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('CompanySettlements/' + settlementID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  createBraintreeCustomer(
    braintreeObj,
    customer,
    currency,
    companyID
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      var newCustomer = {
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        CustomerID: customer.PK_CustomerID,
        Email: customer.Email, //Remove this when the prod-api is updated to accept CustomerID instead
        NonceFromClient: braintreeObj.nonce,
        Identifyer: braintreeObj.details.lastTwo || braintreeObj.details.email,
        PaymentType: braintreeObj.details.cardType || 'PayPal',
        Currency: currency,
        FK_CompanyID: companyID
      };

      this._http
        .post('PaymentMethods/AddPaymentMethodFromBraintree', newCustomer)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  setDefaultPayment(paymentMethodID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .put('PaymentMethods/SetDefault/' + paymentMethodID, {})
        .subscribe(
          (res) => {
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getSingle(companyID): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.company && this.company.PK_CompanyID === parseInt(companyID)) {
        resolve(this.company);
      } else {
        this._http.get('Companies/' + companyID).subscribe(
          (company) => {
            this.company = company;
            resolve(company);
          },
          (err) => {
            if (err.errorName === 'notFoundError') {
              resolve(null);
            } else {
              reject(this.translate.instant('SOMETHING_WENT_WRONG'));
            }
          }
        );
      }
    });
  }
  searchOrgNumber(orgnumberOrEmail, countryID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(`Companies/by-orgnumber-email/${orgnumberOrEmail}/${countryID}`)
        .subscribe({
          next: (res) => {
            if (res.PK_CompanyID) {
              resolve(res);
            } else {
              reject('Unknown error');
            }
          },
          error: (err) => {
            if (err.status === 204) {
              reject('notfound');
            } else {
              reject(err.errorName);
            }
          }
        });
    });
  }
  ownedByCustomer(customerID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('Companies/GetCompanyOwnedByCustomer/' + customerID)
        .subscribe(
          (company) => {
            resolve(company);
          },
          (err) => {
            if (err.errorName === 'notFoundError') {
              resolve(null);
            } else {
              reject(this.translate.instant('SOMETHING_WENT_WRONG'));
            }
          }
        );
    });
  }
  update(company): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        company.ModifiedByUserID = user.FK_UserID;
        this._http.put('Companies', company).subscribe(
          (res) => {
            resolve(true);
          },
          (err) => {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
      });
    });
  }
  delete(company): Promise<any> {
    return new Promise((resolve) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_DELETE_COMPANY'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((doDelete) => {
          if (doDelete) {
            this._http.delete('Companies/' + company.PK_CompanyID).subscribe(
              (res) => {
                resolve(true);
              },
              (err) => {
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
    });
  }

  create(company): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        company.ModifiedByUserID = user.FK_UserID;
        company.CreatedByUserID = user.FK_UserID;
        company.FK_BaseID = baseID;
        company.PaymentDay = 1;
        company.CreatedOn = this.gf.getDateAsUTC();
        company.isActive = true;
        company.Keys = this.gf.guid4();

        this._http.post('Companies', company).subscribe(
          (res) => {
            //this.company = res;
            if (res.datas) {
              resolve(res.datas);
            } else {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG'),
                this.translate.instant('CLOSE')
              );
              resolve(false);
            }
          },
          (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
      });
    });
  }
  createByCustomer(company, ownedByCustomer): Promise<any> {
    //FIX: This is a weird way to do this. Fix later
    return new Promise((resolve) => {
      let companyToAdd = {
        FK_BaseID: baseID,
        OwnedByCustomerID: ownedByCustomer.PK_CustomerID,
        FK_CountryID: company.FK_CountryID,
        Name: company.Name,
        Phone: '',
        Email: ownedByCustomer.Email,
        Address: company.Address,
        CreatedOn: this.gf.getDateAsUTC(),
        UTC: this.gf.getDateAsUTC(),
        isActive: true,
        isPublic: true,
        Keys: this.gf.guid4()
      };
      this.create(companyToAdd).then((res) => {
        resolve(res);
      });
    });
  }
  init(): Promise<any> {
    //Initializes new company
    return new Promise((resolve) => {
      this.userService.getUser().subscribe((user) => {
        let company = {
          FK_BaseID: baseID,
          OwnedByCustomerID: user.PK_CustomerID,
          ModifiedByUserID: user.FK_UserID,
          CreatedByUserID: user.FK_UserID,
          CreatedOn: this.gf.getDateAsUTC(),
          UTC: this.gf.getDateAsUTC(),
          Keys: this.gf.guid4(),
          isActive: true,
          isPublic: true
        };
        resolve(company);
      });
    });
  }
  approveCompany(PK_CompanyID: number) {
    return this._http.put('/Companies/ApproveAdmin', {
      PK_CompanyID,
      ApprovedByAdmin: true
    });
  }
}
