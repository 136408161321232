<form class="socket-search-form">
  <mat-form-field
    class="socket-search-field {{ matFormFieldClass }}"
    [ngClass]=""
    [floatLabel]="floatingLabel">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      type="text"
      matInput
      #autoComplete
      [matAutocomplete]="auto"
      (keyup)="onSearch($event)"
      (focus)="initSocket($event)"
      (blur)="clearSocket()"
      [formControl]="searchInputCtrl"
      [required]="inputRequired"
      name="search" />
    <button
      matTextSuffix
      mat-icon-button
      type="button"
      class="toolbar-button"
      *ngIf="searchInputCtrl.value"
      (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>{{ errorMessage }}</mat-error>
  </mat-form-field>
  <!-- <ng-content #auto="matAutocomplete"></ng-content> -->
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selectItem($event)">
    <mat-option *ngFor="let item of searchItems" [value]="item">
      <ng-template
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{
          $implicit: { item, searchInputCtrl }
        }"></ng-template>
    </mat-option>
  </mat-autocomplete>
</form>
