<h2 mat-dialog-title>{{ title || 'Choose connector' }}</h2>
<div mat-dialog-content class="dialog-content">
  <div
    *ngFor="let connector of connectors"
    class="mb-2 flex min-w-[300px] items-center rounded-2xl border-[1px] border-solid border-current p-4 hover:cursor-pointer hover:bg-green-950 hover:text-white"
    (click)="chooseConnector(connector)">
    <div class="mr-2">
      <i
        class="con-{{
          connector.ConnectorTypes[0].IconName
        }} align-middle point-icon text-2xl"></i>
    </div>
    <div>
      <div>
        <span *ngIf="OcppVersion === VERSION_201">{{'EVSE_ID' | translate}} {{connector.EvseNumber}} - </span>{{'POINT_ID' | translate}} {{connector.ConnectorID}}
      </div>
    </div>
  </div>
  <div class="mt-4">
    <h3 class="manual-heading">{{ 'or choose manually' }}</h3>
    <div>
      <mat-form-field class="w-full">
        <mat-label>{{ getEVSENameByOcppVersion() | translate }}</mat-label>
        <input
          matInput
          autofocus="false"
          type="number"
          [(ngModel)]="manual.evseID"
          name="EVSE_ID" />
      </mat-form-field>
    </div>
    <div *ngIf="OcppVersion == VERSION_201">
      <mat-form-field class="w-full">
        <mat-label>{{ 'CONNECTOR_ID' | translate }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="manual.connectorID"
          name="CONNECTOR_ID" />
      </mat-form-field>
    </div>
  </div>
  <div class="mt-4" *ngIf="inputs?.length">
    <form
      [formGroup]="pointsForm"
      #pointsFormRef="ngForm"
      (ngSubmit)="handleSubmit()"
      novalidate>
      <h3 class="manual-heading">{{ 'SET_PARAMETERS' | translate }}</h3>
      <div>
        <ng-container *ngFor="let item of inputs">
          <div *ngIf="item.type === 'text' || item.type === 'number'">
            <mat-form-field class="w-full" floatLabel="auto">
              <mat-label>{{ item.placeholder }}</mat-label>
              <input
                matInput
                [type]="item.type"
                [formControlName]="item.name" />
              <mat-error *ngIf="item.required">{{ 'REQUIRED' | translate }}</mat-error>
            </mat-form-field>

            <div *ngIf="item.name === 'idTag' || item.name === 'parentIdTag'" class="mb-2">
              <span
                 *ngFor="let token of item?.data?.tokens || []"
                 (click)="fillValue(item.name, token.value)"
                 class="mr-2 cursor-pointer text-cyan-500 hover:text-cyan-900 text-sm">
                  {{ token.name }}
              </span>
            </div>
          </div>
          <mat-form-field class="w-full" *ngIf="item.type === 'datetime-local'">
            <mat-label>{{ item.placeholder }}</mat-label>
            <input
              matInput
              [required]="item.required"
              [name]="item.name"
              [type]="item.type"
              [formControlName]="item.name" />
          </mat-form-field>
          <mat-form-field  *ngIf="item.type === 'select'" class="w-full">
            <mat-label>{{ item.placeholder }}</mat-label>
            <mat-select
            [formControlName]="item.name"
              [required]="item.required">
              <mat-option *ngFor="let item of item.options" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="item.required">{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="sendEvseAndConnector()">
    {{ 'OK' | translate }}
  </button>
  <button mat-raised-button (click)="this.dialogRef.close()" type="button">
    {{ 'CANCEL' | translate }}
  </button>
</div>
