import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { TableOptions } from '../../classes/table-options';
import { DialogService } from '../dialog/dialog.service';
import { GlobalFunctions } from '../global-functions/global-functions.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snackbarConfig } from '../../defaults';
import { UserSearchDialogComponent } from 'src/app/components/admin/users/user-search/user-search-dialog/user-search-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UsersService {
  user: any;
  users: any = [];

  constructor(
    private _http: HttpInterceptorService,
    private dialogService: DialogService,
    private gf: GlobalFunctions,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  getCustomers(options: TableOptions = new TableOptions()): Promise<any> {
    return new Promise((resolve, reject) => {
      let limit = options.limit;
      let startIndex = options.limit * options.offset;
      let sortType = options.prop;
      let sortDirection = options.dir === 'asc' ? 'Ascending' : 'Descending';
      let search = options.search;

      this._http
        .get(
          'Customers/GetCustomers/' +
            startIndex +
            '/' +
            limit +
            '/' +
            sortType +
            '/' +
            sortDirection +
            '/' +
            search +
            '/'
        )
        .subscribe(
          (users) => {
            resolve(users);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  /**
   * Opens search dialog to select customer
   */
  selectCustomer(): Promise<any> {
    return new Promise((resolve) => {
      let dialogRef: MatDialogRef<UserSearchDialogComponent>;

      dialogRef = this.dialog.open(UserSearchDialogComponent);

      dialogRef.afterClosed().subscribe((customer) => {
        resolve(customer);
      });
    });
  }
  select(user) {
    this.user = user;
  }
  getUser(id, reload?): Promise<any> {
    return new Promise((resolve) => {
      if (this.user && !reload && this.user.PK_CustomerID === id) {
        resolve(this.user);
      } else {
        this._http.get('Customers/' + id).subscribe(
          (user) => {
            resolve(user);
          },
          (err) => {
            resolve({});
          }
        );
      }
    });
  }
  updateCustomer(customer): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Customers', customer).subscribe(
        (newCustomer) => {
          resolve(newCustomer);
        },
        (err) => {
          resolve({});
        }
      );
    });
  }
  updateUser(user): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Users', user).subscribe(
        (newUser) => {
          resolve(newUser);
        },
        (err) => {
          resolve({});
        }
      );
    });
  }
  updateEmail(email, userID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .post('Users/UpdateEmailAddress', { Email: email, UserID: userID })
        .subscribe(
          (res) => {
            resolve(res.success);
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }
  searchUser(email = '', phone = '', countryindicator = ''): Promise<any> {
    return new Promise((resolve) => {
      if (!email && !phone && !countryindicator) {
        resolve(false);
      } else {
        this._http
          .get(
            'Customers/Search/' +
              email.trim().toLowerCase() +
              '/' +
              phone.trim() +
              '/' +
              countryindicator.trim()
          )
          .subscribe(
            (user) => {
              if (user.length) {
                resolve(user[0]);
              } else {
                this.snackBar.open(
                  this.translate.instant('USER_NOT_FOUND'),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            },
            (err) => {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                  errorName: err.errorName
                }),
                this.translate.instant('CLOSE')
              );
              resolve(false);
            }
          );
      }
    });
  }
  checklist(userID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Users/UserCheckList/' + userID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve(null);
        }
      );
    });
  }
  checkUnpaid(customerID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Invoices/UnpaidInvoices/' + customerID).subscribe(
        (res) => {
          if (res.length) {
            resolve(res);
          } else {
            resolve([]);
          }
        },
        (err) => {
          resolve([]);
        }
      );
    });
  }
  getPreferences(userID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('UserPreferences/GetUserPreferences/' + userID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve({});
        }
      );
    });
  }
  savePreferenses(preferences): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('UserPreferences', preferences).subscribe(
        (res) => {
          this.snackBar.open(
            this.translate.instant('PREFERENCES_UPDATED'),
            null,
            snackbarConfig
          );
          resolve(true);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  getActiveSessions(userID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('ServiceSessions/Active/' + userID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve([]);
        }
      );
    });
  }
  getAccessCards(customerID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('AccessCards/ByCustomer/' + customerID).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve([]);
        }
      );
    });
  }
  addAccessCard(card): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('AccessCards', card).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          if (err.errorName === 'rfidAlreadyExists') {
            this.snackBar.open(
              this.translate.instant('RFID_ALREADY_EXISTS'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          } else if (err.errorName === '500 Internal Server Error') {
            //FIX: We only do this because backend devs are working slow.
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        }
      );
    });
  }
  updateAccessCard(card): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('AccessCards', card).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          if (err.errorName === 'rfidAlreadyExists') {
            this.snackBar.open(
              this.translate.instant('RFID_ALREADY_EXISTS'),
              this.translate.instant('CLOSE')
            );
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              }),
              this.translate.instant('CLOSE')
            );
          }
          resolve(false);
        }
      );
    });
  }
  deleteAccessCard(cardID): Promise<any> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_DELETE_CARD'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((ok) => {
          if (ok) {
            this._http.delete('AccessCards/' + cardID).subscribe(
              (res) => {
                this.snackBar.open(
                  this.translate.instant('CARD_DELETED'),
                  null,
                  snackbarConfig
                );
                resolve(true);
              },
              (err) => {
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
    });
  }
  generateInvoices(userID): Promise<any> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_GENERATE_INVOICES'),
          null,
          this.translate.instant('GENERATE')
        )
        .subscribe((ok) => {
          if (ok) {
            this._http
              .get('Invoices/GenerateInvoicesForAUser/' + userID)
              .subscribe(
                (res) => {
                  this.snackBar.open(
                    this.translate.instant('INVOICES_GENERATED'),
                    null,
                    snackbarConfig
                  );
                  resolve(true);
                },
                (err) => {
                  this.snackBar.open(
                    this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                      errorName: err.errorName
                    }),
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  resetPassword(userID): Promise<any> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_SEND_EMAIL'),
          this.translate.instant('RESET_PASSWORD_WARNING'),
          this.translate.instant('SEND')
        )
        .subscribe((ok) => {
          if (ok) {
            this._http.get('Users/ResetPass/' + userID).subscribe(
              (res) => {
                this.snackBar.open(
                  this.translate.instant('EMAIL_SENT'),
                  null,
                  snackbarConfig
                );
                resolve(false);
              },
              (err) => {
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
    });
  }
  createNewPassword(userID): Promise<any> {
    return new Promise((resolve, reject) => {
      let suggestedPass = Math.random().toString(36).substr(2, 10);
      this.dialogService
        .input(
          this.translate.instant('SET_NEW_PASSWORD'),
          this.translate.instant('NEW_PASSWORD'),
          this.translate.instant('SET'),
          null,
          null,
          suggestedPass
        )
        .subscribe((newPass) => {
          if (newPass) {
            this._http
              .post('Users/SetPasswordAdmin', {
                PK_UserID: userID,
                NewPassword: newPass
              })
              .subscribe(
                (res) => {
                  if (res.success) {
                    resolve(true);
                  } else {
                    reject('Unknown error');
                  }
                },
                (err) => {
                  reject(err.errorName);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  getPaymentMethods(customerID, getInactive = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('PaymentMethods/' + customerID + '/' + getInactive)
        .subscribe(
          (res) => {
            resolve(res);
          },
          () => {
            reject();
          }
        );
    });
  }
  deleteUser(userID): Promise<boolean> {
    return new Promise((resolve) => {
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_DELETE_USER'),
          null,
          this.translate.instant('DELETE')
        )
        .subscribe((ok) => {
          if (ok) {
            this._http.delete('Users/' + userID).subscribe(
              (res) => {
                this.snackBar.open(
                  this.translate.instant('USER_DELETED'),
                  null,
                  snackbarConfig
                );
                resolve(true);
              },
              (err) => {
                this.snackBar.open(
                  this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                    errorName: err.errorName
                  }),
                  this.translate.instant('CLOSE')
                );
                resolve(false);
              }
            );
          }
        });
    });
  }
  deletePaymentMethod(methodID): Promise<boolean> {
    return new Promise((resolve) => {
      this._http.delete('PaymentMethods/' + methodID).subscribe(
        (res) => {
          this.snackBar.open(
            this.translate.instant('METHOD_DELETED'),
            null,
            snackbarConfig
          );
          resolve(true);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  setDefaultPaymentMethod(methodID): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('PaymentMethods/SetDefault/' + methodID, {}).subscribe(
        (res) => {
          this.snackBar.open(
            this.translate.instant('DEFAULT_PAYMENT_SET'),
            null,
            snackbarConfig
          );
          resolve(res);
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }

  getSupportedLanguages() {
    return this._http.get('Users/GetSupportedLanguages');
  }
  getTimeZones() {
    return this._http.get('UserPreferences/GetTimezones');
  }
}
